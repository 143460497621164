<template>
  <div class="app-container">
    <el-row class="margin-bottom-20">
      <el-col :lg="16" :xs="16" :offset="0">
        <el-input prefix-icon="el-icon-search" size="mini" placeholder="Título" v-model="searchForm.query"
          v-on:change="handleSearchFilter()" clearable></el-input>
      </el-col>
      <el-col :lg="2" :xs="8" :offset="0">
        <el-button @click.stop.prevent="handleSearchFilter" class="margin-left-10" size="mini" type="primary"
          icon="el-icon-search">Buscar</el-button>
      </el-col>
      <el-col :lg="2" :xs="8" :offset="0">
        <el-button class="margin-left-10" @click.stop.prevent="handleClean" size="mini" type="warning"
          icon="el-icon-delete">Limpiar</el-button>
      </el-col>
    </el-row>
    <el-row justify="center">
      <el-col style="display: flex; justify-content: center;" :lg="4" :md="4" :sm="4" :xs="12">
        <el-select v-on:change="handleSearchFilter" size="mini" v-model="searchForm.section" placeholder="Sección">
          <el-option
            v-for="item in sections"
            :key="item.value"
            :label="item.label"
            :value="item.value">
         </el-option>
        </el-select>
      </el-col>
      <el-col style="display: flex; justify-content: center;" :lg="4" :md="4" :sm="4" :xs="12">
        <el-select v-on:change="handleSearchFilter" size="mini" v-model="searchForm.isActive" placeholder="Estatus">
          <el-option
            v-for="item in status"
            :key="item.value"
            :label="item.label"
            :value="item.value">
         </el-option>
        </el-select>
      </el-col>
    </el-row>
    <!---- Paginacion -->
    <el-row type="flex" justify="end">
      <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
        :total="parseInt(totalPages)">
      </el-pagination>
    </el-row>
    <el-row type="flex" justify="start">
        <el-button @click.stop.prevent="handleCreateProject" class="margin-left-10" size="mini" type="success"
          icon="el-icon-plus">Agregar Proyecto</el-button>
    </el-row>
    <!---- Tabla de Proyectos ----->
    <el-table size="mini" v-loading="loading" :data="list" element-loading-text="Loading" height="600" fit border
      highlight-current-row style="width: 100%">
      <el-table-column label="Título" fixed align="center" width="200">
        <template slot-scope="scope">
          <span><el-link @click="showPreviewProject(scope.row._id)">{{ scope.row.title !== undefined ? scope.row.title : 'N/D' }}</el-link></span>
        </template>
      </el-table-column>
      <el-table-column label="ID" width="100" align="center">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" placement="top">
            <div slot="content">{{ scope.row._id !== undefined ? scope.row._id : 'No disponible' }}</div>
            <span>{{ scope.row._id !== undefined ? scope.row._id.toString().substr(-4) : 'N/D' }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="Producto Stripe" align="center" width="120">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" placement="top">
            <div slot="content">{{ scope.row.productIdStripe !== undefined ? scope.row.productIdStripe : 'No disponible' }}</div>
            <el-link @click="handleShowStripe(scope.row.productIdStripe, scope.row.keyName)" type="primary">{{ scope.row.productIdStripe !== undefined ? scope.row.productIdStripe.toString().substr(-4) : 'N/D' }}</el-link>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="Producto Paypal" align="center" width="120">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" placement="top">
            <div slot="content">{{ scope.row.productIdPaypal !== undefined ? scope.row.productIdPaypal : 'No disponible' }}</div>
            <el-link @click="handleShowPaypal(scope.row.productIdPaypal, scope.row.keyName)" type="primary">{{ scope.row.productIdPaypal !== undefined ? scope.row.productIdPaypal.toString().substr(-4) : 'N/D' }}</el-link>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="Imagen" width="100" align="center">
        <template slot-scope="scope">
              <div class="guide-image">
                <el-image
                  style="width: 50px; height: 50px"
                  :src="`${pathServer + scope.row.image}`"
                  fit="scale-down"
                  :description="scope.row.description"
                  :preview-src-list="[pathServer + scope.row.image]">
                </el-image>
              </div>
        </template>
      </el-table-column>
      <el-table-column label="Default" align="center" width="120">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isDefault !== undefined" :type="scope.row.isDefault ? 'default' : 'info'">{{ scope.row.isDefault ? 'Sí' : 'No' }}</el-tag>
          <el-tag v-else type="info">N/D</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Estatus" align="center" width="150">
        <template slot-scope="scope">
          <el-tag  v-if="scope.row.isActive"  size="small" type="success">{{ validateStatus(scope.row.isActive) }}</el-tag>
          <el-tag  v-else size="small" type="info">{{ validateStatus(scope.row.isActive) }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Mostrar en App" width="120" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.showInApp !== undefined" :type="scope.row.showInApp ? 'default' : 'info'">{{ scope.row.showInApp ? 'Sí' : 'No' }}</el-tag>
          <el-tag v-else type="info">N/D</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Sección" align="center" width="150">
        <template slot-scope="scope">
          <span>{{ scope.row.section !== undefined ? validateSection(scope.row.section) : 'N/D' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Nombre de Clave" align="center" width="150">
        <template slot-scope="scope">
          <span>{{ scope.row.keyName !== undefined ? scope.row.keyName : 'N/D' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Descripción" align="center" width="250">
        <template slot-scope="scope">
          <span>{{ scope.row.description !==undefined ? scope.row.description : 'N/D'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="Fecha de Registro" width="180" align="center">
        <template slot-scope="scope">
          <span >{{ scope.row.created_at | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Crear plan" width="180" fixed="right" align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" plain @click="handleCreateStripe(scope.row.productIdStripe, scope.row.keyName)">Stripe</el-button>
          <el-button size="mini" type="warning" plain @click="handleCreatePaypal(scope.row.productIdPaypal, scope.row.keyName)">Paypal</el-button>
        </template>
      </el-table-column>
      <el-table-column label="Opciones" fixed="right" width="130" align="center">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEditProject(scope.row._id)">Editar</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { search, getPage, getProject } from '@/api/projects.js'
const confElement = document.getElementById('config')
const conf = JSON.parse(confElement.innerHTML)
const urlServer = conf.baseApi
export default {
  name: 'Projects',
  data () {
    return {
      pathServer: urlServer,
      searchForm: {
        query: '',
        section: undefined,
        isActive: undefined
      },
      filterDateRegisterRange: '',
      totalPages: '',
      currentPage: '',
      loading: false,
      sizePerPage: 25,
      locations: null,
      types: null,
      list: [
      ],
      sections: [
        {
          label: 'Primera Sección',
          value: '1'
        },
        {
          label: 'Segunda Sección',
          value: '2'
        },
        {
          label: 'Tercera Sección',
          value: '3'
        },
        {
          label: 'Cuarta Sección',
          value: '4'
        }
      ],
      status: [
        {
          label: 'Activo',
          value: true
        },
        {
          label: 'Inactivo',
          value: false
        }
      ]
    }
  },
  mounted () {
    this.handleSearchFilter()
  },
  methods: {
    // ---- Data guides ------
    handleCreateProject () {
      this.$router.push('projects/create')
    },
    handleDownloadDocument (file) {
    },
    handleEditProject (id) {
      console.log(id)
      this.$router.push(`projects/edit/${id}`)
    },
    // --- data search ----
    async handleSearchFilter () {
      this.loading = true
      await search(this.searchForm)
        .then((response) => {
          console.log(response.data, null, 3)
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleChangeItemPage (val) {
      this.loading = true
      this.sizePerPage = val
      await getPage(this.currentPage, val, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleCurrentChange (page) {
      this.currentPage = page
      this.loading = true
      await getPage(page, this.sizePerPage, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async showPreviewProject (id) {
      try {
        const response = await getProject(id)
        if (response.success) {
          window.open(response.data.preview, '_blank')
        } else {
          console.log(response)
        }
      } catch (err) {
        this.$message.error({
          type: 'info',
          message: 'Ha ocurrido un error al obtener los datos'
        })
      }
    },
    handleClean () {
      this.searchForm.query = ''
      this.searchForm.section = undefined
      this.searchForm.isActive = undefined
      this.handleSearchFilter()
    },
    validateStatus (isActive) {
      return isActive ? 'Activo' : 'Inactivo'
    },
    validateSection (section) {
      switch (section) {
        case '1':
          return 'I Sección'
        case '2':
          return 'II Sección'
        case '3':
          return 'III Sección'
        default:
          return 'N/D'
      }
    },
    handleShowStripe (idProductStripe) {
      this.$router.push({ path: 'stripe/plan', query: { product: idProductStripe } })
    },
    handleShowPaypal (idProductPaypal) {
      this.$router.push({ path: 'paypal/plan', query: { product: idProductPaypal } })
    },
    handleCreateStripe (idProductStripe, keyName) {
      this.$router.push({ path: 'stripe/create', query: { product: idProductStripe } })
    },
    handleCreatePaypal (idProductPaypal, keyName) {
      this.$router.push({ path: 'paypal/create', query: { product: idProductPaypal } })
    }
  }
}
</script>

<style lang="css">

</style>
